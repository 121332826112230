<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>销售明细</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>

    <el-container>

      <el-main>
        <!-- 列表内容 -->
        <div class="main">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="时间段">
              <el-date-picker v-model="paytime" type="daterange" @change="paytimechange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onCurrentChange(1)">查询</el-button>

              <el-button type="primary" round @click="onorderby(0)">按销量排序</el-button>
              <el-button type="primary" round @click="onorderby(1)">按照销售额</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="adminList" border="" style="width: 100%">

            <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
            <el-table-column prop="productnum" label="销量" align="center"></el-table-column>

            <el-table-column prop="totalprice" label="销售额" align="center"></el-table-column>

          </el-table>
        </div>

      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getStatisticsproductRequest } from '@/api/home'
export default {
  name: 'advlist',

  data() {
    return {
      id: 0,
      orderno: '',
      nickname: '',
      userphone: '',
      giftenddate: [],
      giftenddatestr: '',
      paytime: [],
      paytimestr: '',
      shopname: '',
      iType: -1,
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
      orderby: 0,
      dfrow: {},
    }
  },

  created() {
    this.onCurrentChange(1)
  },
  methods: {
    getList() {
      getStatisticsproductRequest({
        paytime: this.paytimestr,
        orderby: this.orderby,
      }).then((res) => {
        let data = res.data
        this.adminList = data
      })
    },
    paytimechange(e) {
      console.info(e)
      this.paytimestr = e.join(',')
    },
    onorderby(e) {
      console.info(e)
      this.orderby = e
      this.getList()
    },

    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
  },
}
</script> 

<style lang="less" scoped>
</style>